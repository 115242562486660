<template>
  <div>
    <DarkModeSwitcher />
    <div class="down" style="z-index: 2 !important"></div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5"> </a>
          <div class="my-auto">
            <!-- <div class="logo-web">
              <h1 style="font-size:150px">USED</h1>
              <h1 style="font-size:100px; margin-top:100px">BRANDS</h1>
            </div> -->
            <div class="logo-web flex justify-center items-center">
              <!-- <h1 style="font-size: 150px">BOBA</h1>
              <h1 style="font-size: 100px; margin-top: 100px">HUB</h1> -->
              <img
                src="@/assets/images/boba-hub-logo.svg"
                alt="Logo"
                class="w-full"
              />
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              bg-theme-light-2
              dark:bg-theme-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
            "
          >
            <div class="xl:hidden block flex justify-center">
              <div class="my-auto mt-5">
                <div class="logo-mobile dark:text-theme-1">
                  <img
                    src="@/assets/images/boba-hub-logo.svg"
                    alt="Logo"
                    class=""
                  />
                </div>
              </div>
            </div>
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-white
              "
              :class="isRTL ? ' xl:text-right' : ' xl:text-left'"
            >
              {{ i18n('forgotPassword.title') }}
            </h2>
            <div
              class="intro-x mt-2 xl:text-white text-center"
              :class="isRTL ? ' xl:text-right' : ' xl:text-left'"
            >
              {{ i18n('forgotPassword.desc1') }}<br />
              {{ i18n('forgotPassword.desc2') }}
            </div>
            <div class="intro-x mt-8">
              <input
                type="text"
                class="
                  intro-x
                  login__input
                  form-control
                  py-3
                  px-4
                  border-gray-300
                  block
                  dark:bg-dark-9
                "
                :placeholder="i18n('forgotPassword.emailAddress')"
                v-model="form.email"
              />
            </div>
            <div
              v-if="error"
              class="
                alert alert-danger alert-dismissible
                show
                flex
                items-center
                mb-2
                mt-2
              "
              role="alert"
              style="z-index: 2 !important"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2 ml-2" />
              {{ errorData }}
            </div>
            <div
              class="
                intro-x
                mt-5
                xl:mt-8
                text-center
                xl:text-left
                grid grid-cols-12
                login__input
              "
              :class="isRTL ? 'xl:text-right' : 'xl:text-left'"
            >
              <button
                class="
                  btn
                  bg-theme-31
                  text-white
                  py-3
                  px-4
                  mt-3
                  xl:mt-0
                  align-top
                  lg:col-span-6
                  col-span-12
                "
                style="border: unset !important"
                :class="isRTL ? 'xl:ml-3' : 'xl:mr-3'"
                :disabled="loading"
                @click="doSubmit()"
              >
                <span v-if="!loading">{{
                  i18n('forgotPassword.requestResetLink')
                }}</span>
                <span v-if="loading">{{ i18n('common.loading') }}</span>
                <LoadingIcon
                  icon="three-dots"
                  color="#FFFFFF"
                  style="margin: 0 4px"
                  v-if="loading"
                />
              </button>
              <button
                class="
                  btn
                  bg-theme-32
                  text-black
                  py-3
                  px-4
                  mt-3
                  xl:mt-0
                  align-top
                  lg:col-span-6
                  col-span-12
                "
                @click="goToLogin()"
              >
                {{ i18n('forgotPassword.login') }}
              </button>
              <i18nFlags class="mt-10" @click="changeLoginClass()" />
            </div>
            <!-- <SuccessNotification message="Password reset e-mail Sent" /> -->
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
// import SuccessNotification from '@/components/notifications/success-notification.vue'
import { useStore } from '@/store'
import Toastify from 'toastify-js'

export default defineComponent({
  components: {
    DarkModeSwitcher
    // SuccessNotification
  },

  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.forgotPassword')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.forgotPassword')
    }
  },
  mounted() {
    if (this.isRTL) {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login-rtl')
    } else {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    }
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loadingPasswordResetEmail'
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  setup() {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const error = ref(false)
    const errorData = ref('')
    const form = ref({
      email: ref('')
    })
    const notificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      error,
      errorData,
      form,
      darkMode,
      notificationToggle
    }
  },
  methods: {
    ...mapActions({
      doSendPasswordResetEmail: 'auth/doSendPasswordResetEmail'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    validateEmail(email) {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },
    changeLoginClass() {
      if (this.isRTL) {
        cash('body').removeClass('login').addClass('login-rtl')
      } else {
        cash('body').removeClass('login-rtl').addClass('login')
      }
    },
    // goToSignup() {
    //   this.$router.push('/auth/register')
    // },
    goToLogin() {
      this.$router.push('/auth/login')
    },
    async doSubmit() {
      try {
        const email = this.form.email
        if (email.length == 0) {
          throw this.i18n('forgotPassword.errors.emptyFields')
        }
        if (!this.validateEmail(email)) {
          throw this.i18n('forgotPassword.errors.wrongEmail')
        }
        await this.doSendPasswordResetEmail(email)
        // this.notificationToggle()
      } catch (error) {
        this.error = true
        this.errorData = error
      }
    }
  }
})
</script>

<style lang="scss">
.dark .btn-outline-secondary:hover {
  --tw-bg-opacity: 0.2;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.logo-web {
  width: 550px !important;
  text-align: center !important;
  margin-bottom: 90px !important;
  font-family: 'Times New Roman', sans-serif !important;
}
.logo-mobile {
  text-align: center !important;
  font-family: 'Times New Roman', sans-serif !important;
  // margin-bottom: 20px !important;
  margin-bottom: -14px;
  margin-top: -45px;
}

.logo-web-img {
  margin-left: 50px;
}
[dir='rtl'] .logo-web-img {
  margin-right: 50px;
}
</style>
